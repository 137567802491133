.App {
    /* text-align: center; */
    margin: 5px;
    border: 1px;
    border-radius: 0px;
    /* box-shadow: 0 4px 8px 0 rgba(0; 0; 0; 0.2); 0 6px 20px 0 rgba(0; 0; 0; 0.19); */
}
.button {
    padding: 0.5rem 5rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
    background-color: #007bff;
    border-color: #007bff;
    color: white;
    cursor: pointer;
}
.button-pop {
    padding: 0.25rem 2.5rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
    background-color: #007bff;
    border-color: #007bff;
    color: white;
}
.App-logo {
    margin: 0px;
    width: 50%;
    background-color: #fff;
    padding: 16px;
    position: relative;
    @media all and (min-width: 520px) {
        padding: 40px;
        margin: 100px auto;
        width: 60vw;
    }

    @media all and (min-width: 520px) and (max-width: 1024px) {
        width: 90vw;
    }
}

/* @media (prefers-reduced-motion: no-preference) {
.App-logo {
  animation: App-logo-spin infinite 20s linear;
}
} */
.link {
    color: '#ffffff !important';
}

.boxing {
    width: 75%;
    border: '1px solid black';
    border-radius: '2px';
    padding: '25px';
    display: 'flex';
    justify-content: 'center';
    align-items: 'center';
    margin-bottom: '15px';
    background-color: '#ffffff';
    box-shadow: '0px 0px 9px 0px rgba(0;0;0;0.1)';
    min-width: '1000px';
}
.popup {
    position: fixed;
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    /* background-color: rgba(0;0;0; 0.5);   */
}
.popup\_inner {
    position: absolute;
    left: 25%;
    right: 25%;
    top: 25%;
    bottom: 25%;
    margin: auto;
    border-radius: 10px;
    background: white;
}
.navbar-light .navbar-nav .nav-link {
    color: #007bff !important;
}
.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}
.menuButton {
    width: 260px;
    height: 140px;
    background-color: #f5f5f5;
    color: #9d9d9d;
    /* @media all and (max-width: 520px) {
      width:  110px;
      height:  80px;
      font-size:  12px !important;
  };
  @media all and (max-width: 768px) {
      font-size:  16px;
  }; */

    cursor: pointer;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    border-radius: 4px !important;
    font-size: 26px;
    color: #9c9c9c;
    /* @media all and (min-width: 520px) {
      // :not(:last-child) {
      border-right:  2px solid #c4c4c4 !important;
      // }
  } */

}
.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
/*Formconfigure component*/

.footer {
    box-shadow: rgba(212, 214, 216, 0.3) -1px -1px 2px 1px;
}
#btn-back-to-top {
    position: fixed;
    bottom: 160px;
    right: 60px;
    display: none;
    border-radius: 50%;
    padding-bottom: 10px;
}
#btn-back-to-bottom {
    position: fixed;
    bottom: 120px;
    right: 60px;
    display: none;
    border-radius: 50%;
    padding-bottom: 10px;
}

/* Counter for 5 minutes Expire*/
.show-counter {
    padding: 0.5rem;
    display: flex;
    justify-content: center;
}
.show-counter .countdown {
    line-height: 1.25rem;
    padding: 0 0.75rem 0 0.75rem;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.show-counter .countdown.warning {
    color: #0d6efd;
}
.show-counter .countdown.success {
    color: #198754;
}

.show-counter .countdown > p {
    margin: 0;
}

.show-counter .countdown > span {
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 0.5rem;
}

.backArrow {
    padding: 12px 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #007bff;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
}
/* End Login User Expire*/
