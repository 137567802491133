/* * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  .container {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    padding: 16px;
  }
  
  .section {
    width: 48%;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #f9f9f9;
  } */
  
  /* First Section (Tile Layout) */
  /* .section-tiles {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  } */
  
  /* .tile {
    flex: 1 1 calc(33.33% - 16px); /* 3 tiles per row */
    /* min-width: 100px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  /* }  */
  
  /* Second Section (Radios Layout) */
  /* .section-radio {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .section-radio div {
    display: flex;
    align-items: center;
  } */
  
  /* Mobile View */
  /* @media (max-width: 768px) {
    .container {
      flex-direction: column;
    }
  
    .section {
      width: 100%;
    } */
  
    /* Make tiles horizontal in mobile */
    /* .tile {
      flex: 1 1 100%; /* Make tile boxes 100% width on mobile */
    /* } */
  /* } */ */
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  .container {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    padding: 16px;
    margin-top: 12px;
  }
  
  .section {
    width: 48%;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #f9f9f9;
    position: relative;
    text-align: center;
  }
  
  /* Center Label and Dropdown */
  .section-label {
    position: absolute;
    top: -35px;
    left: 20%;
    transform: translateX(-50%);
    font-weight: bold;
    margin-bottom: '10px';
    margin-top: '10px';
  }
  
  .section-dropdown { 
    position: absolute;
    height: 35px;
    top: -45px;
    left: 50%;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    margin-top: 5px;
    color: #888feb;
    font-weight: bold;
    
  }
  
  /* First Section (Tile Layout) */
  .section-tiles {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
  
  .tile {
    flex: 1 1 calc(33.33% - 16px); /* 3 tiles per row */
    min-width: 100px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Second Section (Radios Layout) */
  .section-radio {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .section-radio div {
    display: flex;
    align-items: center;
  }

  .image-container {
    width: auto; /* Set desired width */
    height: 200px; /* Set desired height */
    overflow: hidden; /* Ensures the image does not overflow */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .image-container img {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Ensures the image fills the container without distortion */
  }
  
  /* Mobile View */
  @media (max-width: 768px) {
    .container {
      flex-direction: column;
    }
  
    .section {
      width: 100%;
    }
  
    /* Make tiles horizontal in mobile */
    .tile {
      flex: 1 1 100%; /* Make tile boxes 100% width on mobile */
    }
  
    /* Adjust dropdown and label for smaller screens */
    .section-label {
      top: -25px;
    }
  
    .section-dropdown {
      width: 100%;
    }
  }
    
  
  