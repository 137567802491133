.field-row{
    display: flex;
    justify-content: flex-start;
    align-items: center;
 }
 .question-container {
  box-shadow: 
    0 2px 1px -1px rgba(0,0,0,0.2)
    0 1px 1px 0 rgba(0,0,0,0.141)
    0 1px 3px 0 rgba(0,0,0,0.122)
  
 }
 .hideElement {
    display: none;
 }
 .ascending-icon {
   font-size: 24px;
    color: red;
    font-weight: bold;
    cursor: pointer;
 }

 .decending-icon {
   font-size: 24px;
    color: green;
    font-weight: bold;
    cursor: pointer;
 }

 .download-box {
   width: 50%;
   background-color: #fff;
   padding: 20px;
   border-radius: 5px;
   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
   /* z-Index: 2; */
   margin: 5% 25%;
 }
 .modal-heading {
   background-color: #007bff;
   color: #fff;
   border-radius: 5px;
   height: 60px;
 }
 .dropdown-answers-box {
   height: 30vh;
   overflow-y: auto;
   overflow-x: hidden;
 }
 .dropdown-single-answer {
  height: 15vh;
  overflow-y: auto;
   overflow-x: hidden; 
}
 .dropdown-answers-box::-webkit-scrollbar {
   width: 6px; /* Adjust the width to make the scrollbar thinner */
 }
 
 .dropdown-answers-box::-webkit-scrollbar-track {
   background-color: #f1f1f1; /* Set the background color of the scrollbar track */
 }
 
 .dropdown-answers-box::-webkit-scrollbar-thumb {
   background-color: #888; /* Set the color of the scrollbar thumb */
   border-radius: 3px; /* Adjust the border radius to make the thumb shape */
 }
 
 .dropdown-answers-box::-webkit-scrollbar-thumb:hover {
   background-color: #555; /* Set the color of the scrollbar thumb when hovered */
 }

 .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.left {
  flex: 1;
}

.right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.back-button {
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

.sub-button {
  margin-left: 10px;
  padding: 5px 10px;
  color: #fff;
    background-color: #007bff;
    border: none;
    cursor: pointer;
}
