/* Reset some default styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

 .containerLoader {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Arial, sans-serif;
}

.boxLoader {
    width: 80%;
    max-width: 400px;
    padding: 20px;
    background-color: white;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px; */
    text-align: center;
}

/* Adjustments for small screens */
@media (max-width: 600px) {
    .boxLoader {
        width: 90%;
        padding: 15px;
    }
}
